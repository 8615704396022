import { ActiveAnchorData, IDropDownMenuItem } from '../DropDownMenu.types';

const isAnchorLink = (item: IDropDownMenuItem) =>
  item.link && (item.link.anchorDataId || item.link.anchorCompId);

const isActiveAnchorItem = (
  item: IDropDownMenuItem,
  activeAnchor: ActiveAnchorData,
) =>
  item.link &&
  (item.link.anchorCompId === activeAnchor.compId ||
    item.link.anchorDataId === activeAnchor.dataId);

const findActivePopupItems = (
  items: Array<IDropDownMenuItem>,
  currentPopupId: string,
) =>
  items.filter(item => item.link && item.link.linkPopupId === currentPopupId);

const isCurrentHrefItem = (
  item: IDropDownMenuItem,
  href: string,
  hasActiveAnchorSiblings: boolean,
) =>
  !hasActiveAnchorSiblings &&
  !isAnchorLink(item) &&
  item.link &&
  item.link.href === href;

const findActiveAnchorItem = (
  items: Array<IDropDownMenuItem>,
  activeAnchorData: ActiveAnchorData,
) => {
  const activeAnchorExists = activeAnchorData.compId || activeAnchorData.dataId;
  let result: Set<IDropDownMenuItem> = new Set();

  if (activeAnchorExists) {
    result = new Set(
      items.filter(item => isActiveAnchorItem(item, activeAnchorData)),
    );
  }

  return result;
};

export const getSelectedItems = (
  items: Array<IDropDownMenuItem> = [],
  href = '',
  activeAnchorData: ActiveAnchorData = {},
  currentPopupId = '',
): Set<IDropDownMenuItem> => {
  const activeAnchorItems = findActiveAnchorItem(items, activeAnchorData);
  const activePopupItems = findActivePopupItems(items, currentPopupId);
  const result = new Set([
    ...Array.from(activeAnchorItems),
    ...Array.from(activePopupItems),
  ]);
  const hasActiveAnchorItems = result.size > 0;

  items.forEach(item => {
    const selectedSubItems =
      item.items && item.items.length
        ? getSelectedItems(item.items, href, activeAnchorData)
        : new Set<IDropDownMenuItem>();

    if (
      isCurrentHrefItem(item, href, hasActiveAnchorItems) ||
      (item.link &&
        Object.keys(item.link).length > 0 &&
        selectedSubItems.size > 0)
    ) {
      result.add(item);
    }

    selectedSubItems.forEach(subItem => result.add(subItem));
  });

  return result;
};

export function flattenMenuItemsWithParentId(items: Array<IDropDownMenuItem>) {
  return items.reduce(
    (acc: Array<IDropDownMenuItem>, item: IDropDownMenuItem) => {
      let menuChildren = [];
      if (item.items) {
        menuChildren = item.items.map((subItem: any) => {
          return { ...subItem, parent: item.id };
        });
      }
      return [...acc, ...[item], ...menuChildren];
    },
    [],
  );
}
